import { Dispatch, SetStateAction } from 'react';
import './Picker.scss';


interface PickerProps {
    label?: string;
    pickerValue: string;
    setPickerValue: (value: string) => void | Dispatch<SetStateAction<'tilingOn' | 'tilingOff' | 'both'>>;
    pickerOptions: InputOption[];
}

function Picker({
    label,
    pickerValue,
    setPickerValue,
    pickerOptions
}: PickerProps) {
    return (
        <div className="picker">
            <span className="picker__label">{label}</span>
            <div className="picker__buttons">
                {pickerOptions.map((option) => {
                    if (option.value === pickerValue) return <button className="picker__buttons__button picker__buttons__button--enabled" type="button" onClick={() => setPickerValue(option.value)}>{option.label}</button>
                    else return <button className="picker__buttons__button" type="button" onClick={() => setPickerValue(option.value)}>{option.label}</button>
                })}
            </div>
        </div>
    );
}

export default Picker;
