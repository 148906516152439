import { useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { Grid, Lazy, Mousewheel, Navigation } from 'swiper';
import Thumbnail from '../Thumbnail/Thumbnail';
import './InputThumbnail.scss';

export interface InputThumbnailProps {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * A given name to component
     */
    name?: string;
    /**
     * Display orientation
     */
    type?: 'horizontal' | 'vertical';
    /**
     * Data list
     */
    data?: InputThumbnailData[];
    /**
     * When thumbnail is clicked
     */
    onClick?: (item: any, onClick?: boolean) => void;
    /**
     * When thumbnail is double-clicked
     */
    onDoubleClick?: (item: any) => void;
    /**
     * Current selected item in list
     */
    selectedItems?: any[];
    /**
     * Number of rows to temporarily display on component init
     * to help with less stutty loading
     */
    initRows?: number;
    /**
     * Width of a thumbnail
     */
    thumbnailWidth?: number;
    /**
     * Height of a thumbnail
     */
    thumbnailHeight?: number;
    /**
     * Max height of a thumbnail
     */
    thumbnailMaxHeight?: number;
    /**
     * Thumbnails after this number are lazy loaded
     */
    lazyLoadThreshold?: number;
    /**
     * Number of columns. Must be also set in CSS
     */
    numCol?: number;
    /**
     * If true, user can select thumbnails using arrow keys
     */
    arrowKeyNavigation?: boolean;
    /**
     * If true, shows a checkbox top-right of thumbnail
     */
    showSelectionCheckbox?: boolean;
    /**
     * Callback function to execute on left arrow key
     */
    onLeftArrowKey?: () => void;
    /**
     * Callback function to execute on right arrow key
     */
    onRightArrowKey?: () => void;
}

/**
 * InputThumbnail data
 */
export interface InputThumbnailData {
    /**
     * The value of the data
     */
    value: any;
    /**
     * Image thumbnail src
     */
    src: string;
    /**
     * If true, display thumbnail with active style
     */
    isActive: boolean;
}

/**
 * Thumbnail slider wrapped around Swiper package
 */
function InputThumbnail({
    className = '',
    name,
    type = 'vertical',
    data = [],
    onClick,
    onDoubleClick,
    selectedItems,
    numCol = 2,
    initRows = 0,
    thumbnailWidth,
    thumbnailHeight,
    thumbnailMaxHeight,
    lazyLoadThreshold,
    arrowKeyNavigation = false,
    showSelectionCheckbox = false,
    onLeftArrowKey,
    onRightArrowKey
}: InputThumbnailProps) {
    const layout = {
        vertical: {
            numCol,
            itemsPerRow: initRows,
            spaceBetween: 0
        },
        horizontal: {
            numCol: 1,
            itemsPerRow: 3,
            spaceBetween: 10
        }
    };
    const [swiperRef, setSwiperRef] = useState<SwiperClass>();
    const [selectedItemPage, setSelectedItemPage] = useState<number>(0);
    const [numRows, setNumRows] = useState<number>(layout[type].itemsPerRow);
    const thumbnailRef = useRef<HTMLImageElement>();
    const isVertical = type === 'vertical';

    useEffect(() => {
        function handleKeydown(event: KeyboardEvent) {
            switch (event.code) {
                case 'ArrowLeft':
                    onLeftArrowKey && onLeftArrowKey();
                    break;
                case 'ArrowRight':
                    onRightArrowKey && onRightArrowKey();
                    break;
                default:
                    break;
            }
        }

        goToSlide(selectedItemPage);
        if (arrowKeyNavigation) document.addEventListener('keydown', handleKeydown);
        return () => document.removeEventListener('keydown', handleKeydown);
    }, [selectedItems]);

    /**
     * Stores rendered data components in state; only updating when the data changes
     * or when the selected item is changed (to apply active style)
     */
    const useData = useMemo(() => {
        return data.map((item, index) => {
            if (item.isActive) {
                const page = Math.floor(index / layout[type].numCol);
                setSelectedItemPage(page);
            }
            const doLazyLoad = lazyLoadThreshold ? index + 1 > lazyLoadThreshold : false;

            return (
                <SwiperSlide key={item.src}>
                    <Thumbnail
                        // @ts-ignore
                        reff={thumbnailRef}
                        src={item.src}
                        isActive={item.isActive}
                        onClick={() => onClick && onClick(item.value, true)}
                        onDoubleClick={() => onDoubleClick && onDoubleClick(item.value)}
                        showSelectionCheckbox={showSelectionCheckbox}
                        width={thumbnailWidth}
                        height={thumbnailHeight}
                        maxHeight={thumbnailMaxHeight}
                        lazyLoad={doLazyLoad}
                        fetchpriority="low"
                    />
                </SwiperSlide>
            );
        });
    }, [data]);

    /**
     * Calc and set the number of rows/cols shown in Swiper
     */
    const updateThumbnailsLayout = (swiper: SwiperClass) => {
        if (isVertical) {
            const refThumbnailHeight = thumbnailRef.current?.height;
            if (refThumbnailHeight) {
                const padding = 5;
                const newNumRows = Math.floor(swiper.height / (refThumbnailHeight + padding));
                setNumRows(newNumRows);
                goToSlide(selectedItemPage);
            }
        }
    };

    /**
     * Moves to slide with currently selected item
     */
    const goToSlide = (index: number) => setTimeout(() => swiperRef?.slideTo(index, 500), 200);

    if (data.length === 0) return <EmptyList />;
    return isVertical ? (
        <div className={`ith ith--vertical ${className}`}>
            <NavigationArrow className={`bi-chevron-up ${name}-prev`} />
            {thumbnailSlider()}
            <NavigationArrow className={`bi-chevron-down ${name}-next`} />
        </div>
    ) : (
        <div className={`ith ith--horizontal ${className}`}>
            <NavigationArrow className={`bi-chevron-left ${name}-prev`} />
            {thumbnailSlider()}
            <NavigationArrow className={`bi-chevron-right ${name}-next`} />
        </div>
    );

    /**
     * Thumbnails shown in a vertical/horizontal layout
     */
    function thumbnailSlider() {
        return (
            <Swiper
                onUpdate={(swiper) => {
                    setSwiperRef(swiper);
                    updateThumbnailsLayout(swiper);
                }}
                onResize={updateThumbnailsLayout}
                className={`swiper__${type}`}
                slidesPerView={numRows}
                slidesPerGroup={numRows}
                direction={type}
                threshold={6}
                autoHeight={isVertical}
                spaceBetween={layout[type].spaceBetween}
                // preloadImages={false} // TODO: lazy load
                modules={[Grid, Lazy, Mousewheel, Navigation]}
                // Handles the scroll range of pages
                grid={
                    isVertical
                        ? {
                              fill: 'column',
                              rows: layout[type].numCol
                          }
                        : {}
                }
                // TODO: lazy load
                // lazy={{
                //     enabled: true,
                //     loadOnTransitionStart: true
                // }}
                mousewheel={{ sensitivity: 1 }}
                // Handles next/previous buttons and arrow styling
                navigation={{
                    prevEl: `.${name}-prev`,
                    nextEl: `.${name}-next`,
                    disabledClass: 'disabled'
                }}
            >
                {useData}
            </Swiper>
        );
    }
}

/**
 * Navigation arrows
 */
function NavigationArrow({
    className = '',
    onClick
}: {
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
    return <i className={`ith__navigation-arrow bi ${className}`} onClick={onClick} />;
}

/**
 * Empty list
 */
function EmptyList() {
    return (
        <div className="empty">
            <h4 className="empty__text">No thumbnails</h4>
        </div>
    );
}

export default InputThumbnail;
