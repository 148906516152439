import { useContext, useMemo, useState } from 'react';
import { CameraContext } from '../../contexts/CameraContext';
import { ContentWithSidebarLayout } from '../../components/layout';
import {
    ImageViewer,
    InputButton,
    InputCalendar,
    InputToggle,
    RadioCollapse
} from '../../components/common';
import useMediaQuery from '../../hooks/useMediaQuery';
import ImageThumbnails from '../../components/thumbnails/ImageThumbnails/ImageThumbnails';
import './Images.scss';
import DebugWindow from '../../components/common/DebugWindow/DebugWindow';
import { InferenceType } from '../../types/enums';
import Slider from '../../components/common/Slider/Slider';
import Picker from '../../components/common/Picker/Picker';

function Images() {
    const { isMobileDevice, isTabletDevice } = useMediaQuery();
    const { firstImage, sessionCookies, selectedCamera } = useContext(CameraContext);

    /**
     * TODO: Temporary variable using for demo AI Insights integration
     * this will be removed when BE return it into session after demo
     */
    const [insightConfig, setInsightConfig] = useState<{
        isEnable: boolean;
        peopleEnabled: boolean;
        labelsEnabled: boolean;
    }>({
        isEnable: false,
        peopleEnabled: false,
        labelsEnabled: true,
    });

    const {
        images,
        selectedImage,
        selectedDate,
        fetchImage,
        updateSelectedDate,
        getImageUrl,
        getDownloadUrl,
        imageFetchLog,
        clearImageFetchLog
    } = firstImage;

    const { cookies, isAutoRefresh, toggleAutoRefresh, isFillMode, toggleFillMode } =
        sessionCookies;
    const userCanDownloadImage = cookies.data?.features?.can_download_images;

    // This is a feature flag set in imageviewer backend
    const userSessionAiInsights = cookies.data?.features?.can_view_ai_insights;
    // Camera will return aiInsights=true if user has permissions (imageviewer backend)
    const userPermissionCameraAiInsights = selectedCamera?.aiInsights;
    // I'd think this is the same as above, but I'm not sure
    const cameraAiInsightsEnabled = selectedCamera?.features.can_view_ai_insights;

    const userCanViewAiInsights = userSessionAiInsights
        && userPermissionCameraAiInsights
        && cameraAiInsightsEnabled;

    /**
     * If the user permissions to view ai insights was removed
     * or if the feature flag was disabled, disable ai insights
     */
    if (userCanViewAiInsights !== true && insightConfig.isEnable === true) {
        setInsightConfig((prev) => {
            return { ...prev, isEnable: false };
        });
    }

    // State to store the currently selected confidence level for inferences
    const [confidenceLevel, setConfidenceLevel] = useState(20);
    // State to store the currently selected option to display tiling inferences
    const [tilingOption, setTilingOption] = useState<'tilingOn' | 'tilingOff' | 'both' | string>('both');


    /**
     * Filter inferences based on confidence level set on slider
     */
    const filteredInferences = useMemo(() => {
        return selectedImage?.inferences?.filter((inference) => {
            if (inference.confidence >= (confidenceLevel / 100)) {
                if (tilingOption === 'tilingOn') return inference.has_tiling && inference;
                else if (tilingOption === 'tilingOff') return !inference.has_tiling && inference;
                return inference;
            }
            return false;
        });
    }, [confidenceLevel, tilingOption, selectedImage?.inferences]);

    return (
        <ContentWithSidebarLayout
            className="images"
            leftSidebarContent={leftSidebarContent()}
            mainContent={mainContent(confidenceLevel)}
            rightSidebarContent={rightSidebarContent()}
        />
    );

    function leftSidebarContent() {
        return (
            <>
                <InputCalendar
                    className={`calendar ${isMobileDevice() && 'input-calendar--mobile'}`}
                    type={isTabletDevice() ? 'dropdown' : 'default'}
                    onSelectedValue={updateSelectedDate}
                    value={new Date(selectedDate)}
                    minDate={selectedCamera && new Date(selectedCamera.minDate)}
                    maxDate={selectedCamera && new Date(selectedCamera.maxDate)}
                    arrowKeyNavigation
                />
                {!isMobileDevice() && (
                    <ImageThumbnails
                        type="vertical"
                        images={images}
                        onClick={fetchImage}
                        getImageUrl={getImageUrl}
                        selectedImage={selectedImage}
                        arrowKeyNavigation
                    />
                )}
            </>
        );
    }

    function mainContent(confidence: number) {
        // console.log('REACT_APP_IMAGE_FETCH_DEBUG', process.env.REACT_APP_IMAGE_FETCH_DEBUG);
        return (
            <div className="images__content">
                {selectedImage?.src && (
                    <ImageViewer
                        imageSrc={selectedImage.src}
                        inferences={(userCanViewAiInsights && insightConfig.isEnable)
                            ? filteredInferences
                            : []}
                        isFillMode={isFillMode}
                        isDisabled={!userCanDownloadImage}
                        insightConfig={insightConfig}
                        showFullscreenIcon
                        confidenceLevel={confidence}
                        tilingOption={tilingOption}
                    />
                )}
                {isMobileDevice() && (
                    <ImageThumbnails
                        type="horizontal"
                        images={images}
                        onClick={fetchImage}
                        selectedImage={selectedImage}
                        getImageUrl={getImageUrl}
                    />
                )}
                {process.env.REACT_APP_IMAGE_FETCH_DEBUG === 'true' && (
                    <DebugWindow log={imageFetchLog} clear={clearImageFetchLog} />
                )}
            </div>
        );
    }

    function rightSidebarContent() {
        /**
         * Downloads the currently viewed image.
         * If CORS, the browser opens it in a new tab instead for security purposes.
         * Read more: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-download
         */
        const handleImageDownload = async () => {
            if (!selectedImage) return;

            const downloadUrl = getDownloadUrl(selectedImage);
            if (!downloadUrl) throw new Error('Cannot download image');

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = ''; // filename can only be declared server-side
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return (
            <div className="images__rsc">
                <InputToggle
                    className="images__rsc__toggle"
                    label="Auto Refresh"
                    checked={isAutoRefresh}
                    onChange={() => toggleAutoRefresh()}
                    subtitle="Enable auto refresh to automatically load the most recent photo into image viewer"
                />
                <InputToggle
                    className="images__rsc__toggle"
                    label="Fill Mode"
                    checked={isFillMode}
                    onChange={() => toggleFillMode()}
                    subtitle="Enable fill mode to view photo without margins on the sides"
                />
                {userCanViewAiInsights &&
                <>
                    <InputToggle
                        className="images__rsc__toggle"
                        label="AI Insights"
                        checked={insightConfig.isEnable}
                        onChange={() => {
                            setInsightConfig({
                                ...insightConfig,
                                isEnable: !insightConfig.isEnable
                            });
                        }}
                    />
                    {insightConfig.isEnable && (
                        <>
                            <RadioCollapse
                                className="images__rsc__toggle"
                                label="People"
                                checked={insightConfig.peopleEnabled}
                                onChange={() => {
                                    setInsightConfig({
                                        ...insightConfig,
                                        peopleEnabled: !insightConfig.peopleEnabled
                                    });
                                }}
                                collapsedContent={collapsedPeopleContent()}
                            />
                            {insightConfig.peopleEnabled &&
                            <>
                                <RadioCollapse
                                    className="test"
                                    label="Labels"
                                    checked={insightConfig.labelsEnabled}
                                    onChange={() => {
                                        setInsightConfig((prev) => {
                                            return { ...prev, labelsEnabled: !prev.labelsEnabled };
                                        });
                                    }}
                                />
                                <Slider
                                    label="Confidence"
                                    sliderValue={confidenceLevel}
                                    setSliderValue={setConfidenceLevel}
                                />
                                <Picker
                                    label="Tiling Options"
                                    pickerValue={tilingOption}
                                    setPickerValue={setTilingOption}
                                    pickerOptions={[
                                        { value: 'tilingOn', label: 'Tiling' },
                                        { value: 'tilingOff', label: 'No Tiling' },
                                        { value: 'both', label: 'Both' },
                                    ]}
                                />
                            </>}
                        </>
                    )}
                </>}
                {process.env.REACT_APP_IMAGE_FETCH_DEBUG === 'true' && (
                    <p style={{ fontSize: 12 }}>{selectedImage?.src}</p>
                )}

                <InputButton
                    className="images__rsc__download"
                    type="button"
                    label="Download Image"
                    onClick={handleImageDownload}
                    disabled={!userCanDownloadImage || !selectedImage}
                />
            </div>
        );
    }

    function collapsedPeopleContent() {
        const inferences = filteredInferences || [];
        const personFiltered = inferences.filter(
            (item) => item.type.label === InferenceType.PERSON
        );
        const color = personFiltered.length > 0 ? personFiltered[0].type.colour : 'grey';
        return (
            <div className="toggle-content">
                <div className="toggle-content__color-dot" style={{ backgroundColor: color }} />
                <span className="toggle-content__label">
                    Number of people detected:{' '}
                    <span className="toggle-content__label__count">{`${personFiltered.length}`}</span>
                </span>
            </div>
        );
    }
}

export default Images;
