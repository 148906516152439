import React, { Dispatch, SetStateAction } from 'react';
import './Slider.scss';

interface SliderProps {
    label?: string;
    sliderValue: number;
    setSliderValue: (e: any) => void | Dispatch<SetStateAction<number>>;
}

function Slider({
    label,
    sliderValue,
    setSliderValue
}: SliderProps) {
    return (
        <div className="slider-custom">
            {!!label && <span className="slider-custom--label">{label} ({sliderValue}%)</span>}
            <input
                className="slider-custom--slider"
                type="range"
                min="0"
                max="100"
                onChange={(e) => setSliderValue(e.target.value)}
                value={sliderValue}
            />
        </div>
    );
}

export default Slider;
