import { InferenceType } from '../../../types/enums';
import './CoordinateAnnotation.scss';

interface CoordinateAnnotationProps {
    /**
     * Object inferences
     */
    inferences?: Inferences[];
    /**
     * TODO: Temporary variable using for demo AI Insights integration
     * this will be refactor when BE return it into session after demo
     */
    insightConfig?: {
        isEnable: boolean;
        peopleEnabled: boolean;
        labelsEnabled: boolean;
    }
}

function CoordinateAnnotation({ inferences = [], insightConfig }: CoordinateAnnotationProps) {
    const { peopleEnabled } = insightConfig || {};

    const inferencesByType = () => {
        let result: Inferences[] = [];
        if (inferences?.length > 0) {
            if (peopleEnabled) {
                result = inferences.filter((item) => item.type.label === InferenceType.PERSON);
            }
        }
        return result;
    };

    return (
        <>
            {inferencesByType().map((item, index) => {
                const { x, y, width, height, type, confidence } = item;

                const confidenceLevel = `(${Math.trunc(confidence * 100)}%)`;

                return (
                    <div
                        key={index}
                        className="coordinate-annotation"
                        style={{
                            width: `${width * 100}%`,
                            height: `${height * 100}%`,
                            top: `${y * 100}%`,
                            left: `${x * 100}%`,
                            border: `2px solid ${type.colour}`
                        }}
                    >
                        {insightConfig?.labelsEnabled && (
                            <div
                                className="coordinate-annotation__label"
                                style={{
                                    background: type.colour,
                                    color: 'white'
                                }}
                            >
                                {type.label} {confidenceLevel}
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
}

export default CoordinateAnnotation;
