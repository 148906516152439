import { useEffect, useMemo, useRef, useState } from 'react';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { images } from '../../../assets';
import './ImageViewer.scss';
import CoordinateAnnotation from '../CoordinateAnnotation/CoordinateAnnotation';

interface ImageViewerProps {
    /**
     * Image src
     */
    imageSrc: string;
    /**
     * Additional classnames for containing div
     */
    className?: string;
    /**
     * If true, fills container's empty space with image
     */
    isFillMode?: boolean;
    /**
     * If true, right-click press does not show context menu
     */
    isDisabled?: boolean;
    /**
     * If true, show an icon to make image fill viewport
     */
    showFullscreenIcon?: boolean;
    /**
     * If true, shows an outline around containing div and image
     */
    debugOutline?: boolean;
    /**
     * Callback when fullscreen button is triggered
     */
    onFullscreen?: () => void;
    /**
     * Detected objects coordinates
     */
    inferences?: Inferences[];
    /**
     * TODO: Temporary variable using for demo AI Insights integration
     * this will be refactor when BE return it into session after demo
     */
    insightConfig?: {
        isEnable: boolean;
        peopleEnabled: boolean;
        labelsEnabled: boolean;
    },
    confidenceLevel: number,
    tilingOption?: 'tilingOn' | 'tilingOff' | 'both' | string;
}

/**
 * An image viewing component wrapped around react-zoom-pan-pinch
 */
function ImageViewer({
    imageSrc,
    className = '',
    isFillMode,
    isDisabled,
    showFullscreenIcon,
    debugOutline,
    onFullscreen,
    inferences,
    insightConfig,
    confidenceLevel,
    tilingOption
}: ImageViewerProps) {
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const [viewerRef, setViewerRef] = useState<ReactZoomPanPinchRef>();
    const containerRef = useRef<HTMLDivElement>({} as HTMLDivElement);
    const imageRef = useRef<HTMLImageElement>({} as HTMLImageElement);

    // Attach listener to window resize
    // useEffect(() => new ResizeObserver(refreshViewerRef).observe(containerRef.current), []);

    // Refresh viewer's image on fill and/or fullscreen
    useEffect(() => refreshViewerRef(), [isFillMode, isFullscreen]);

    // Enable/disable right-clicking on image/image container
    useEffect(() => {
        const preventDefault = (event: MouseEvent) => event.preventDefault();
        isDisabled
            ? containerRef.current.addEventListener('contextmenu', preventDefault)
            : containerRef.current.removeEventListener('contextmenu', preventDefault);
    }, [isDisabled]);

    /**
     * Checks to see if the image has horizontal padding inside the container
     */
    const imageHasHorizontalPadding = () => {
        const { offsetWidth: conWidth, offsetHeight: conHeight } = containerRef.current;
        const { offsetWidth: imgWidth, offsetHeight: imgHeight } = imageRef.current;
        const width = Math.max(conWidth, 0) - Math.max(imgWidth, 0);
        const height = Math.max(conHeight, 0) - Math.max(imgHeight, 0);
        return width > height;
    };

    /**
     * Calcs and scales the image to the container size
     */
    const refreshViewerRef = (ref?: ReactZoomPanPinchRef) => {
        // Calc and set the scale to fill/contain the image inside the container
        const { offsetWidth: conWidth, offsetHeight: conHeight } = containerRef.current;
        const { offsetWidth: imgWidth, offsetHeight: imgHeight } = imageRef.current;

        let zoomScale = 1;
        if (isFillMode || isFullscreen) {
            const widthRatio = conWidth / imgWidth;
            const heightRatio = conHeight / imgHeight;
            zoomScale = imageHasHorizontalPadding() ? widthRatio : heightRatio;
        }

        ref
            ? ref.zoomToElement(imageRef.current, zoomScale, 400, 'easeOut')
            : viewerRef?.zoomToElement(imageRef.current, zoomScale, 400, 'easeOut');
    };

    /**
     * Toggle fullscreen mode and call callback function
     */
    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
        onFullscreen && onFullscreen();
    };

    if (!imageSrc) return null;

    // /**
    //  * Filter inferences based on confidence level set on slider
    //  */
    // const filteredInferences = useMemo(() => {
    //     return inferences?.filter((inference) => {
    //         if (inference.confidence >= (confidenceLevel / 100)) {
    //             if (tilingOption === 'tilingOn') return inference.has_tiling && inference;
    //             else if (tilingOption === 'tilingOff') return !inference.has_tiling && inference;
    //             return inference;
    //         }
    //         return false;
    //     });
    // }, [confidenceLevel, tilingOption, inferences]);

    return (
        <div
            className={`imageviewer ${className} ${isFullscreen && 'imageviewer--fullscreen'}`}
            ref={containerRef}
        >
            {showFullscreenIcon && (
                <img
                    className={`imageviewer__fullscreen-icon
                        ${isFullscreen && 'imageviewer__fullscreen-icon--active'}`}
                    onClick={toggleFullscreen}
                    src={isFullscreen ? images.default.compress : images.default.expand}
                    alt="fullscreen-icon"
                />
            )}
            <TransformWrapper
                onInit={(ref) => {
                    setViewerRef(ref);
                    // Janky way to reposition image after load
                    setTimeout(() => refreshViewerRef(ref), 1000);
                }}
                initialScale={1}
                minScale={1}
                centerOnInit
            >
                <TransformComponent
                    wrapperStyle={{
                        border: debugOutline ? '1px solid aqua' : 'none',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        background: 'black'
                    }}
                    contentStyle={{
                        border: debugOutline ? '1px solid orange' : 'none',
                        minWidth: '100%',
                        ...(isFillMode ? { width: '100%' } : { height: '100%' }),
                        position: 'relative'
                    }}
                >
                    <img
                        className="imageviewer__image"
                        src={imageSrc}
                        alt="Camera Shot"
                        ref={imageRef}
                        loading="eager"
                        // @ts-ignore
                        // eslint-disable-next-line
                        fetchpriority="high"
                    />
                    <CoordinateAnnotation
                        insightConfig={insightConfig}
                        inferences={inferences}
                    />
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
}

export default ImageViewer;
