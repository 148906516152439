import './RadioCollapse.scss';

interface RadioCollapseProps extends InputProps {
    /**
     *  Label text left to the control
     */
    label?: string;
    /**
     * Additional classname of containing div
     */
    className?: string;
    /**
     * Checked state of the checkbox
     */
    checked?: boolean;
    /**
     * Component inside collapse section
     */
    collapsedContent?: React.ReactNode;
}

/**
 * A radio collapse component.
 */
function RadioCollapse({ label, className = '', checked, onChange, collapsedContent }: RadioCollapseProps) {
    return (
        <div className={`radio-collapse ${className}`}>
            <span className="radio-collapse__toggle">
                <div className="radio-collapse__toggle_radio-control">
                    {/* eslint-disable-next-line */}
                    <label className="radio-collapse__toggle_radio-control__switch">
                        <input
                            type="checkbox"
                            checked={checked}
                            onChange={(e) => onChange && onChange(e.target.value)}
                        />
                        <span className="radio-collapse__toggle_radio-control__switch__slider" />
                    </label>
                    {label && <span className="radio-collapse__toggle__label">{label}</span>}
                </div>
                {collapsedContent && <NavigationArrow className={`bi-chevron-${checked ? 'up' : 'down'}`} onClick={() => onChange && onChange('on')} />}
            </span>
            {collapsedContent && checked && <div>{collapsedContent}</div>}
        </div>
    );
}

/**
 * Navigation arrows
 */
function NavigationArrow({
    className = '',
    onClick
}: {
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
    return <i className={`radio-collapse__navigation-arrow bi ${className}`} onClick={onClick} />;
}

export default RadioCollapse;
