/**
 * Screen width breakpoints for devices in pixels
 */
export enum ScreenDevice {
    DESKTOP = 1080,
    TABLET = 992,
    MOBILE = 768
}

/**
 * An Image's API response field
 */
export enum ImageSize {
    THUMBNAIL = 'thumbnailUrlSuffix',
    LARGE = 'largeUrlSuffix',
    FULLSIZE = 'fullSizeUrlSuffix'
}

/**
 * Video types. An API response field
 */
export enum VideoType {
    DAILY = 'dailiesUrl',
    MONTHLY = 'monthliesUrl'
}

/**
 * Inferences type
 */
export enum InferenceType {
    PERSON = 'PERSON',
}
