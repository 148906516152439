import { useMemo } from 'react';
import { ImageSize } from '../../../types/enums';
import InputThumbnail, {
    InputThumbnailData,
    InputThumbnailProps
} from '../../common/InputThumbnail/InputThumbnail';

interface ImageThumbnailsProps extends InputThumbnailProps {
    /**
     * Image list data
     */
    images: Image[];
    /**
     * Current selected image in list
     */
    selectedImage?: Image;
    /**
     * Context's function using for get full image path
     */
    getImageUrl?: (image: Image, imageSize?: ImageSize) => string | undefined;
    /**
     * Callback function with clicked image parameters
     */
    onClick?: (image: Image, onClick?: boolean) => any;
}

/**
 * A custom component to display Image thumbnails.
 * Wrapped around and extends InputThumbnail for type checking.
 */
function ImageThumbnails({
    name,
    type = 'vertical',
    images,
    selectedImage,
    getImageUrl,
    onClick,
    arrowKeyNavigation = true
}: ImageThumbnailsProps) {
    const numCol = 2;

    // Format data to be used in InputThumbnail
    const useData: InputThumbnailData[] = useMemo(() => {
        return images.map((image) => {
            const src = (getImageUrl && getImageUrl(image)) || '';
            const isActive = image.filename === selectedImage?.filename;
            return { value: image, src, isActive };
        });
    }, [images, selectedImage]);

    /**
     * Selects previous image when left arrow key is pressed
     */
    const selectPreviousImage = () => {
        const currIndex = images.findIndex((x) => x.filename === selectedImage?.filename);
        const prevIndex = currIndex - 1;
        const validIndex = prevIndex >= 0;
        if (validIndex) {
            const previousImage = images[prevIndex];
            onClick && onClick(previousImage, true);
        }
    };

    /**
     * Selects next image when left arrow key is pressed
     */
    const selectNextImage = () => {
        const currIndex = images.findIndex((x) => x.filename === selectedImage?.filename);
        const nextIndex = currIndex + 1;
        const validIndex = nextIndex < images.length;
        if (validIndex) {
            const nextImage = images[nextIndex];
            onClick && onClick(nextImage, true);
        }
    };

    return (
        <InputThumbnail
            name={name}
            type={type}
            numCol={numCol}
            data={useData}
            thumbnailWidth={144}
            thumbnailHeight={96}
            selectedItems={[selectedImage]}
            onClick={onClick}
            arrowKeyNavigation={arrowKeyNavigation}
            onLeftArrowKey={selectPreviousImage}
            onRightArrowKey={selectNextImage}
            lazyLoadThreshold={-1} // Make all lazy-load
        />
    );
}

export default ImageThumbnails;
